.about {
  background-color: #bf9000;
  /* background-color: rgb(58, 183, 221); */
  font-family: "Lexend Giga", sans-serif;
  color: white;
  margin: 0;
  padding: 1.5em;
  /* font-size: 2em; */
}

.aboutP {
}
.love {
  /* background-color: rgb(242, 107, 91);
  background-color: rgb(223, 236, 241);
  background-color: rgb(0, 54, 59); */
  background-color: #741b47;

  /* background-color: rgb(24, 24, 116); */
  /* color: rgb(5, 40, 87);
  color: rgb(255, 255, 172); */
  color: white;

  font-family: "Lexend Giga", sans-serif;
  font-size: 1.5em;
  padding: 1.5em;
}

@media (min-width: 600px) {
  .hello {
    padding-bottom: 200px;
    padding-top: 150px;
    display: flex;
  }
  .hello-text {
    font-size: 4em;
    width: 85%;
  }
  .myName {
    padding: 0px;
    /* font-size: 4em;  */
  }
  .about {
    padding: 5em;
    padding-bottom: 6em;
    font-size: 1.8em;
  }
  .aboutP {
    text-align: left;
    padding-left: 30%;
    font-size: 0.7em;
  }
  .love {
    padding: 5em;
    padding-bottom: 7em;
  }
  .love-text {
    font-family: "Orbitron", sans-serif;
    padding-left: 6em;
  }
  .sql {
    font-family: "Lexend Giga", sans-serif;
    padding-left: 5em;
  }
  .life {
    font-family: "Lexend Giga", sans-serif;
    padding-left: 10em;
  }
}
