

.projects-header {
    /* font-family: 'Play', sans-serif;  */
    text-align: center;
    color: rgb(95, 215, 236);
    background-color: rgb(94, 53, 160);
    background-color: rgb(113, 33, 160);
    padding: 20px;
}
.projects-list {
    margin: auto;
    padding: 0
}
