.stackContainer {
  /* background-color: rgb(69, 151, 120);  */

  /* font-size: 2em; */
}

.listMain {
  background-color: #38761d;
  /* background-color: #d9ead3; */
  font-family: "Lexend Giga", sans-serif;
  color: white;
  margin: 0;
  padding: 1.5em;
  padding: 0;
}
.listHeader {
  padding: 1em 6.5em;
}

@media (max-width: 800px) {
  .listTotal {
    padding: 1em;
  }
  .list {
    padding: 1em;
  }
  .listHeader {
    padding: 1em 1.5em 0.5em 1em;
  }
}

@media (min-width: 800px) {
  .listTotal {
    padding: 1em;
  }
  .list {
    padding: 1em 3em;
    display: flex;
    justify-content: center;
    justify-items: center;
  }
  .listHeader {
    padding: 1em 3em;
  }
}
@media (min-width: 1400px) {
  .listTotal {
    padding: 1em 2em;
  }
  .list {
    display: flex;
    padding: 1em 4em 3em 4em;
    justify-content: space-evenly;
    justify-items: center;
  }
  .listHeader {
    padding: 1em 4em;
    /* justify-content: flex-start; */
  }
}
