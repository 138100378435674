.slider {
  width: 100%;
  height: 40vh;
  margin: auto;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.slider-wrapper {
  margin: auto;
  display: flex;
  height: 100%;
  width: 100%;
  order: 2;
}
.backArrow {
  order: 1;
  margin: auto;
  padding: 5px;
  z-index: +1;
  color: rgb(3, 62, 114);
}
.nextArrow {
  order: 3;
  margin: auto;
  padding: 5px;
  z-index: +1;
  color: rgb(3, 62, 114);
}
.blog {
  background-color: #d9ead3;
  /* background-color: rgb(4, 161, 114); */
  /* background-color: rgb(250, 156, 69); */
  color: rgb(53, 21, 21);
  padding-bottom: 50px;
}
.othello-game {
  background-color: rgb(117, 69, 250);
  /* background-color: rgb(4, 161, 114); */
  /* background-color: rgb(250, 156, 69); */
  color: white;
  padding-bottom: 50px;
}
.crown-clothing {
  background-color: rgb(31, 16, 47);
  color: white;
  padding-bottom: 50px;
}
.green-thumbs {
  background-color: rgb(114, 47, 2);
  color: white;
  padding-bottom: 50px;
}
.yogi-track {
  background-color: rgb(151, 204, 4);
  color: rgb(53, 21, 21);
  padding-bottom: 50px;
}
.noteful {
  background-color: rgb(3, 119, 243);
  color: white;
  padding-bottom: 50px;
}
.bookmarks {
  background-color: rgb(137, 4, 199);
  color: white;
  padding-bottom: 50px;
}

.calculator {
  background-color: rgb(109, 231, 225);
  color: rgb(53, 21, 21);
  padding-bottom: 50px;
}
.project-header {
  padding-top: 30px;
  padding-left: 2%;
}
.project-li {
  padding: 2%;
}
.text {
  padding: 15px;
}

.li {
  display: inline;
}

@media (min-width: 800px) {
  .slider {
    height: 450px;
    width: 100%;
  }
  .project-li {
    width: 50%;
    margin: auto;
  }
  .project-header {
    padding-top: 30px;
    padding-left: 40px;
  }
  .project-info {
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .text {
    padding: 40px;
  }
}
