.nav {
    font-family: 'Lexend Giga', sans-serif;
    color: rgb(205, 217, 233);
}
.nav-icons {
   margin: 0;
}
#button2 {
    font-family: 'Lexend Giga', sans-serif;
    font-size: 16px;
    text-decoration: none;
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    margin: 10px;
    border: none;
    
}

@media (min-width: 600px){
    .nav{
        display: flex;
        justify-content: space-between;
    }
    .links {
        display: flex;
        padding-top: 6px;
    }
    .icons {
        padding-top: 5px;
        display: flex;
    }
}