main {
    font-family: 'Lexend Giga', sans-serif;
}
header {
    background-color:  rgb(3, 62, 114);
    background-color:  rgb(2, 29, 87);
    margin: 0;  
}

a {
    color: rgb(205, 217, 233);
    padding: 10px;
    text-decoration: none;
}

h1,h2,h3,ul,li,section {
    margin: 0;
    
}
ul {
    list-style-type: none; 
}
 button {
     font-family: 'Orbitron', sans-serif; 
     font-size: 15px;
     color: rgb(250, 235, 214);
     text-decoration: none;
     padding: 5px;
     cursor: pointer;
     background-color: rgb(3, 62, 114);
     border-radius: 10px;
     margin: 10px;
     border: none;
     
 }
.link2 {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
    color: initial;
    font-family: 'Orbitron', sans-serif; 
    font-size: 15px;
    color: rgb(250, 235, 214);
    text-decoration: none;
    padding: 5px; 
    cursor: pointer;
    background-color: rgb(3, 62, 114);
    border-radius: 10px;
    margin: 10px;
    border: none;
}

img {
    max-width: 300px;
    display: block;
    margin: auto;
 }
 .link{
    text-decoration: none;
    color: rgb(205, 217, 233);
    vertical-align: vertical;
 }
  .link:hover  {
    transform: translateY(-2px);
    color: rgb(251, 157, 149);
    cursor: pointer;
  }
  .link-active{
    color: rgb(6, 131, 248);
    border-bottom: 3px solid rgb(6, 131, 248);
    transition: border-bottom .5s ease-in-out;
    background-color:grey
  }

@media (min-width: 600px){
    img {
        /* max-width: 600px;
        max-height:400px; */
        display: block;
        margin: auto;
     }
}

